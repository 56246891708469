import {
  createElement,
  forwardRef,
  type HTMLAttributes,
  type ReactNode,
} from 'react';
import { cn, tv, type VariantProps } from 'tailwind-variants';

type ParagraphProps = VariantProps<typeof paragraph> &
  HTMLAttributes<HTMLParagraphElement> & {
    asChild?: boolean;
    as?: 'p';
    children: ReactNode;
    className?: string;
  };

const paragraph = tv({
  variants: {
    size: {
      xxsmall: 'text-xxs',
      xsmall: 'text-xs',
      small: 'text-sm',
      medium: 'text-base',
      large: 'text-lg',
      xlarge: 'text-xl',
    },
    transform: {
      uppercase: 'uppercase',
    },
  },
  compoundVariants: [
    {
      size: 'large',
      transform: 'uppercase',
      className: 'text-base uppercase',
    },
  ],
});

const Paragraph = forwardRef<HTMLParagraphElement, ParagraphProps>(
  (
    { asChild, as = 'p', children, className, size, transform, ...props },
    ref,
  ) => {
    return createElement(
      as,
      {
        className: paragraph({ size, transform, class: className }),
        ref,
        ...props,
      },
      children,
    );
  },
);

Paragraph.displayName = 'Paragraph';
export default Paragraph;
