/**
 * Determines which image format to use based on the image CDN.
 */
export const getImageFormat = (src: string): string => {
  if (src.endsWith('.svg')) {
    return '';
  }
  if (src.includes('ctfassets')) {
    return '&fm=avif'; // Contentful CDN uses 'fm' flag and supports avif
  }
  if (isNorceCDN(src)) {
    return '&f=webp'; // Norce CDN uses 'f' flag and only supports webp
  }
  return '';
};

const isNorceCDN = (src: string) =>
  /(?:cdn\.(?:nordiskagalleriet|no-ga|lannamobler|lanna)\.(?:no|se|com)|[a-zA-Z0-9-]+\.cdn\.storm\.io|noga(?:\.stage)?\.cdn-norce\.tech)/.test(
    src,
  );
