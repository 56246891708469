import type { CampaignFragment, Maybe } from '@business/gql/graphql';

export const getActiveCampaigns = (
  campaigns: Maybe<CampaignFragment>[] | undefined,
) => {
  const activeCampaigns =
    campaigns?.filter((campaign) => {
      const today = new Date();
      const start = new Date(campaign?.campaignStart);
      const end = new Date(campaign?.campaignEnd);
      return (
        start.getTime() < today.getTime() && end.getTime() > today.getTime()
      );
    }) ?? [];

  return activeCampaigns;
};
