import NextImage, { type ImageProps as NextImageProps } from 'next/image';
import { tv } from 'tailwind-variants';

export const container = tv({ base: 'h-full w-full object-cover' });

type ImageProps = NextImageProps & {
  lazyload?: boolean;
};

// We can't pass both fill and width/height value to Next Image component
const getWidthAndHeightOrFill = ({
  width,
  height,
  fill,
}: {
  height: NextImageProps['height'];
  width: NextImageProps['width'];
  fill: NextImageProps['fill'];
}) => {
  if (!!fill) {
    return {
      fill,
      width: undefined,
      height: undefined,
    };
  } else {
    return {
      width: width ?? undefined,
      height: height ?? undefined,
      fill: undefined,
    };
  }
};

function Image({
  className,
  priority = false,
  lazyload = true,
  ...props
}: ImageProps) {
  return (
    <NextImage
      className={container({ class: className })}
      loading={lazyload && !priority ? 'lazy' : 'eager'}
      priority={priority}
      placeholder={props.blurDataURL ? 'blur' : undefined}
      {...props}
      {...getWidthAndHeightOrFill({
        width: props.width,
        height: props.height,
        fill: props.fill,
      })}
    />
  );
}

Image.displayName = 'Image';

export default Image;
