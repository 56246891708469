export default function localeToCurrency(locale: string) {
  switch (locale) {
    case 'sv-SE':
    case 'sv-se':
    case 'en-SE':
    case 'en-se':
      return 'SEK';
    case 'nb-NO':
    case 'en-NO':
    case 'nb-no':
    case 'en-no':
      return 'NOK';
    case 'da-DK':
    case 'en-DK':
      return 'DKK';
    default:
      return 'EUR';
  }
}
