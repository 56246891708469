'use client';

import { ImageLoader } from 'next/image';
import { getImageFormat } from './getImageFormat';

const imageLoader: ImageLoader = ({ src, width, quality }) => {
  return `${src}?w=${width}&q=${quality || 75}${getImageFormat(src)}`;
};

export default imageLoader;
