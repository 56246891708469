export default function createHref(
  path: string | null | undefined = '/',
): string {
  if (!path) {
    return '/';
  }

  // If the path is a full URL, return it as is and make sure it starts with https
  if (path.startsWith('www')) {
    return `https://${path}`;
  }

  // If the path is a full URL, return it as is with https
  if (path.startsWith('https')) {
    return path;
  }

  // If path is a relative path, return it as is
  if (path.startsWith('/')) {
    return path;
  }

  return `/${path}`;
}
