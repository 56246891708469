import { cn } from '@ngg/storefront-utils';
import Paragraph from '../Paragraph/Paragraph';

type Props = {
  label: string;
  className?: string;
};

export default function Badge(props: Props) {
  const { label, className } = props;

  return (
    <Paragraph
      className={cn([
        'bg-yellow px-2 py-0.5 text-center align-middle !text-xxs uppercase text-yellow-contrast',
        className,
      ])}>
      {label}
    </Paragraph>
  );
}
