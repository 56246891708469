'use client';

import type {
  CategoryProductFragment,
  ProductFragment,
  ProductServiceProduct,
} from '@business/gql/graphql';
import type { Dictionary } from '@ts/dictionary';
import type { Product } from '@ts/product';
import { getMatchingActiveCampaignsForProduct } from '@utils/campaign/getMatchingActiveCampaignsForProduct';
import Badge from '../Badge/Badge';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getCampaignInfo } from '@app/actions/siteinfo/actions';

type ProductType =
  | ProductServiceProduct
  | ProductFragment
  | CategoryProductFragment
  | Product;

const ProductCardBadges = ({
  product,
  dictionary,
}: {
  product: ProductType;
  dictionary: Dictionary;
}) => {
  const [rendered, setRendered] = useState(false);
  const { data } = useQuery({
    queryKey: ['productCardBadges'],
    queryFn: () => getCampaignInfo(),
    select: (response) => {
      const campaigns = getMatchingActiveCampaignsForProduct({
        campaigns: response?.items,
        productFlags: product.flags ?? [],
      });
      const campaign = campaigns.at(0);
      const isCampaignPrice = product.priceType === 'campaign';
      const shouldShowCampaignBadge =
        campaign?.campaignBadge &&
        (isCampaignPrice || campaign.showBadgeForAllProductsInCampaign);
      const shouldShowSaleBadge = !shouldShowCampaignBadge && isCampaignPrice;
      return {
        shouldShowCampaignBadge,
        shouldShowSaleBadge,
        activeCampaign: campaign,
        isNew: product?.flags?.includes('news'),
      };
    },
    enabled: Boolean(product) && rendered,
  });

  // TEMP FIX FOR HYDRATION ERRORS
  useEffect(() => setRendered(true), []);

  if (!rendered) return null;

  return (
    <div className="absolute left-2 top-2 flex flex-col items-start gap-1">
      {data !== undefined && (
        <>
          {data.shouldShowCampaignBadge &&
            data.activeCampaign?.campaignBadge && (
              <Badge label={data.activeCampaign.campaignBadge} />
            )}
          {data.shouldShowSaleBadge && <Badge label={dictionary?.sale} />}
          {data.isNew && (
            <Badge
              className="bg-green text-white/90"
              label={dictionary?.newsBadge}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ProductCardBadges;
