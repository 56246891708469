'use client';
import { TailwindClasses, cn } from '@ngg/storefront-utils';
import createHref from '@utils/url/createHref';
import NextLink from 'next/link';

type Props = {
  href: string | null | undefined;
  locale?: string;
  className?: TailwindClasses;
} & React.ComponentProps<typeof NextLink>;

export default function Link(props: Props) {
  const { href, ...rest } = props;
  return <NextLink {...rest} prefetch={false} href={createHref(href)} />;
}

type LinkOutlinedProps = {
  startIcon?: React.ReactNode;
  className?: TailwindClasses;
  children: React.ReactNode;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

export function LinkOutlined({
  children,
  className,
  startIcon,
  ...rest
}: LinkOutlinedProps) {
  return (
    <a
      className={cn(
        'h-10 bg-beige',
        'border',
        'inline-flex items-center justify-center gap-2 ',
        'px-4 py-3',
        'text-center text-sm font-normal normal-case text-white',
        'select-none',
        'hover:bg-black hover:text-white',
        className,
      )}
      {...(rest as any)}>
      <div className="flex items-center">{startIcon}</div>
      {children}
    </a>
  );
}
