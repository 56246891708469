const getStockStatusType = (stock: string[] | undefined) => {
  if (!stock) return null;

  if (stock.every((status) => status === 'StockItem')) {
    return 'StockItem';
  }

  if (stock.every((status) => status === 'PendingSoldOut')) {
    return 'PendingSoldOut';
  }

  if (stock.every((status) => status === 'DeliveryItem')) {
    return 'DeliveryItem';
  }

  if (stock.every((status) => status === 'SoldOut')) {
    return 'SoldOut';
  }

  if (stock.every((status) => status === 'OutOfStock')) {
    return 'OutOfStock';
  }

  if (stock.every((status) => status === 'Incoming')) {
    return 'Incoming';
  }

  return null;
};

export default getStockStatusType;
