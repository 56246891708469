'use client';

import { useCallback } from 'react';
import type { ComponentPropsWithoutRef } from 'react';
import createLoopEvent from '@lib/utils/createLoopEvent';
import getSearchEngineUserClient from '@utils/getSearchEngineUserClient';

type ProductCardRootProps = ComponentPropsWithoutRef<'div'> & {
  productId: string;
};

export default function ProductCardRoot({
  children,
  productId,
  ...props
}: ProductCardRootProps) {
  const handleClick = useCallback(() => {
    createLoopEvent({
      searchEngineUser: getSearchEngineUserClient(),
      payload: {
        events: [
          {
            type: 'click',
            entity: {
              type: 'Product',
              id: productId,
            },
          },
        ],
      },
    });
  }, [productId]);

  return (
    <div onClick={handleClick} {...props}>
      {children}
    </div>
  );
}
