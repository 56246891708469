import { WholeOrFixed } from '@ts/currency/WholeOrFixed';
import priceFormatterKey from './priceFormatterKey';
import localeToCurrency from './localeToCurrency';

const knownPriceFormatters: { [key: string]: Intl.NumberFormat } = {};

export default function priceFormatter(
  locale: string,
  wholeOrFixed: WholeOrFixed,
): Intl.NumberFormat {
  const key = priceFormatterKey(locale, wholeOrFixed);

  if (key in knownPriceFormatters) {
    return knownPriceFormatters[key];
  }

  if (wholeOrFixed === 'whole') {
    const currency = localeToCurrency(locale);
    const wholeFormatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,

      // These options are needed to round to whole numbers if that's what you want.
      minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
    });

    knownPriceFormatters[key] = wholeFormatter;
    return wholeFormatter;
  }

  const currency = localeToCurrency(locale);
  const fixedFormatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,

    minimumFractionDigits: 2,
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
  });

  knownPriceFormatters[key] = fixedFormatter;

  return fixedFormatter;
}
